<template>
  <CModal :visible="visibleLiveDemo" @close="() => { visibleLiveDemo = false }">
    <AdminProfile :adminId="id" />
  </CModal>
  <CDropdown :color="item" :togglerText="item" variant="btn-group">
    <CDropdownToggle class="dropdown-item" :color="item"><strong>{{ adminId }}</strong></CDropdownToggle>
    <CDropdownMenu>
      <CDropdownItem>
        <li>
          <CButton class="btn btn dropdown-item fw-bold" color="primary" @click="() => { visibleLiveDemo = true }">
            <CIcon class="text-black" icon="cil-user" /> Profile
          </CButton>
        </li>
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
  &nbsp;&nbsp;
  <CAvatar :src="avatar" size="md" /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

  <!-- edit category -->

</template>

<script>
import axios from 'axios'
import avatar from '@/assets/images/avatars/2.jpg'
import AdminProfile from '../Modal/AdminProfile.vue'
export default {
  name: 'AppHeaderDropdownAccnt',
  components: {
    AdminProfile
  },
  data() {
    return {
      avatar: avatar,
      itemsCount: 42,
      visibleLiveDemo: false,
      list: [],
      adminId: '',
      id: '',
    }
  },
  async mounted() {
    const body = {
      admin_id: JSON.parse(localStorage.getItem('nerpafs_admin_id'))
    }
    console.warn("Admin_id ... ", body)
    let result = await axios.post('adm-details-byId', body
    )
    this.list = result.data.data
    this.id = body
    console.log("admin id for props ", this.id)
    this.adminId = this.list[0].admin_name
    console.log('this  list', this.adminId)
  }

}
</script>