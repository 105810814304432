<template>
    <CModalHeader class="bg-black text-white ">
        <CModalTitle class="fw-bold text-white">Admin Profile</CModalTitle>
    </CModalHeader>
    <CModalBody>
        <CForm class="row g-4 fw-bold">
            <CCol md="12">
                <div class="profile-pic">
                    <label class="-label" for="file">
                        <span class="glyphicon glyphicon-camera"></span>
                        <span>Change Image</span>
                    </label>
                    <input id="file" type="file" onchange="loadFile(event)" />
                    <img src="https://cdn.pixabay.com/photo/2017/08/06/21/01/louvre-2596278_960_720.jpg" id="output"
                        width="200" />
                </div>
            </CCol>
            <CCol md="12">
                <CFormInput feedbackValid="Looks good!" id="validationDefault02" label="Name"
                    v-model="itemData.admin_name" required />
            </CCol>
            <CCol md="12">
                <CFormInput feedbackValid="Looks good!" id="validationDefault03" label="Email Id"
                    v-model="itemData.email_id" required />
            </CCol>
            <!-- <CCol md="12">
            <CFormInput feedbackValid="Looks good!" id="validationDefault04" label="Color Codes" required />
        </CCol> -->
        </CForm>
        <CModalFooter>

            <CButton class="bg-black text-white py-1 px-2" type="submit" v-on:click="updateAdminProfile()">Save changes
            </CButton>
            &nbsp;
            <CButton color="secondary" v-on:click="closePage()">
                Close
            </CButton>
        </CModalFooter>

    </CModalBody>
</template>

<script>
import axios from 'axios';
export default {
    name: 'AdminProfile',
    props: ["adminId"],
    data() {
        return {
            itemData: {
                admin_name: '',
                // admin_id: '',
                email_id: '',
                image_profile_url: ''
            }
        }
    },
    async mounted() {
        
        
        const Headers = {
            "Content-type": "application/json; charset=UTF-8",
            "authorization": JSON.parse(localStorage.getItem('nerpafs_admin_id'))
        }
        
        let result = await axios.post(
            'adm-details-byId', this.adminId, {
            headers: Headers
        }
        
        )
        this.itemData = result.data.data[0]
    },

    methods: {

        async updateAdminProfile() {

            const Headers = {
                "Content-type": "application/json; charset=UTF-8",
                "authorization": JSON.parse(localStorage.getItem('nerpafs_admin_id'))
            };
            const body = {
                admin_id: JSON.parse(localStorage.getItem('nerpafs_admin_id')),
                admin_name: this.itemData.admin_name,
                email_id: this.itemData.email_id

            }

            let result = await axios.post("update-adm-profile", body, {
                headers: Headers
            });
            this.$router.go()

        },
        closePage() {
            this.$router.go()
        },

    }

}
</script>

<style scoped>
.profile-pic {
    color: transparent;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all 0.3s ease;
}

.profile-pic input {
    display: none;
}

.profile-pic img {
    position: absolute;
    object-fit: cover;
    width: 165px;
    height: 165px;
    box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.35);
    border-radius: 100px;
    z-index: 0;
}

.profile-pic .-label {
    cursor: pointer;
    height: 165px;
    width: 165px;
}

.profile-pic:hover .-label {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 10000;
    color: #fafafa;
    transition: background-color 0.2s ease-in-out;
    border-radius: 100px;
    margin-bottom: 0;
}

.profile-pic span {
    display: inline-flex;
    padding: 0.2em;
    height: 2em;
}

body {
    height: 100vh;
    background-color: #191815;
    display: flex;
    justify-content: center;
    align-items: center;
}

body a:hover {
    text-decoration: none;
}
</style>
