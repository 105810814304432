<template>
  <CFooter>
    <div>
      <!-- <a>NERPAFS</a> -->
      <span class="ms-1">&copy; {{ new Date().getFullYear() }} NERPAFS </span>
    </div>
    <div class="ms-auto">
      <span class="me-1" target="_blank">Developed By</span>
      <a href="https://www.agicent.com/">Agicent Technologies PVT LTD</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
