<template>
  <CSidebar class="p-3 mb-2 bg-black text-white element" position="fixed" :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible" @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    ">
    <CSidebarBrand class="p-3 mb-2 bg-black text-white">
      <img src="@/assets/images/avatars/121.png" class="" width="80 " height="70" />
    </CSidebarBrand>
    <AppSidebarNav />
    <!-- <CSidebarToggler class="d-none d-lg-flex" @click="$store.commit('toggleUnfoldable')" /> -->
  </CSidebar>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { AppSidebarNav } from './AppSidebarNav'
import { logoNegative } from '@/assets/brand/logo-negative'
import { sygnet } from '@/assets/brand/sygnet'
export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNav,
  },
  setup() {
    const store = useStore()
    return {
      logoNegative,
      sygnet,
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
    }
  },
}
</script>


<style>
.element {
  caret-color: rgba(0, 0, 0, 0);
}
</style>
